<template>
  <div class="dashboard-page center">
    <img :src="$store.state.welcomeImageUrl" alt="" />
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.dashboard-page {
  height: 100%;
}
</style>
